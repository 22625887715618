package fi.bullpen.kmpapp.screens.turnkeyInitEmailRecovery

import androidx.compose.runtime.*
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import fi.bullpen.kmpapp.composables.Scaffold
import fi.bullpen.kmpapp.screens.utils.WebIframeStamper
import fi.bullpen.kmpapp.screens.utils.getScreenModel
import js.turnkey.iframeStamper.TIframeStamperConfig
import js.turnkey.iframeStamper.TurnkeyIframeStamper.IframeStamper
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.*
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*

const val TurnkeyIframeContainerId = "turnkey-iframe-container-id"
const val TurnkeyIframeElementId = "turnkey-iframe-element-id"

data object TurnkeyInitEmailRecoveryScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val screenModel: TurnkeyInitEmailRecoveryScreenModel = getScreenModel()
        val screenState by screenModel.state.collectAsState()

        val uiDisabled = screenState.recoveryState !is RecoveryState.AwaitingAction

        Div({
            id(fi.bullpen.kmpapp.screens.turnkeyRecovery.TurnkeyIframeContainerId)
            style { height(1.px); width(1.px) }
            classes("overflow-hidden", "absolute", "top-0", "left-0")
        }) {}


        LaunchedEffect(Unit) {
            val iframeContainer = document.getElementById(TurnkeyIframeContainerId)

            val iframeStamper = WebIframeStamper(IframeStamper(object : TIframeStamperConfig {
                override var iframeUrl = "https://auth.turnkey.com"
                override var iframeContainer = iframeContainer
                override var iframeElementId = TurnkeyIframeElementId
            }))
            screenModel.setIframeStamper(iframeStamper)
        }

        Scaffold(screenState.telegramBotName) {
            Div({ classes("mt-24", "mb-12") }) {
                Img(src = "/bullpen-bull-black-bg.png", alt = "Bullpen Logo", attrs = {
                    classes("mx-auto")
                    attr("width", "80")
                    attr("height", "80")
                })
                H2({
                    classes(
                        "mt-4", "text-center", "text-3xl", "favorit", "leading-9", "tracking-tight", "text-zinc-900"
                    )
                }) {
                    Text("Add Wallet Recovery Email")
                }
            }

            Div({ classes("mt-10") }) {
                when (screenState.recoveryState) {
                    RecoveryState.InputEmail -> {
                        EnterEmailForm(screenState, screenModel, uiDisabled)
                    }

                    RecoveryState.InputRecoveryCode -> {
                        CreateNewPasskeyForm(screenState, screenModel, uiDisabled)
                    }

                    RecoveryState.Success -> {
                        window.open("https://t.me/${screenState.telegramBotName}?start=start", "_self")
                    }

                    else -> {}
                }
            }
        }
    }
}

@Composable
private fun EnterEmailForm(
    screenState: TurnkeyInitEmailRecoveryScreenState,
    screenModel: TurnkeyInitEmailRecoveryScreenModel,
    uiDisabled: Boolean
) {
    var email by remember { mutableStateOf("") }
    Form(attrs = {
        classes("space-y-4", "p-4", "max-w-lg", "mx-auto")
        onSubmit {
            it.preventDefault()
            screenModel.initRecovery(email)
        }
    }) {
        if (screenState.recoveryState is RecoveryState.Error) {
            Div({
                classes(
                    "bg-red-100", "border", "border-red-400", "text-red-700", "px-4", "py-3", "rounded", "relative"
                )
                attr("role", "alert")
            }) {
                Div({
                    classes("font-bold")
                }) {
                    Text("Authentication Error:")
                }
                Span({
                    classes("block", "sm:inline")
                }) {
                    Text((screenState.recoveryState as RecoveryState.Error).message)
                }
            }
        }
        Div {
            Label(forId = "email", attrs = {
                classes("block", "text-sm", "font-medium", "leading-6", "text-zinc-900")
            }) {
                Text("Email address")
            }
            Div({ classes("mt-2") }) {
                EmailInput(email, attrs = {
                    classes(
                        "block",
                        "w-full",
                        "rounded-md",
                        "border-0",
                        "py-1.5",
                        "text-zinc-900",
                        "shadow-sm",
                        "ring-1",
                        "ring-inset",
                        "ring-zinc-300",
                        "placeholder:text-zinc-400",
                        "focus:ring-2",
                        "focus:ring-inset",
                        "focus:ring-zinc-900"
                    )
                    if (uiDisabled) {
                        disabled()
                    }
                    id("email")
                    name("email")
                    inputMode(InputMode.Email)
                    autoComplete(AutoComplete.email)
                    required()
                    placeholder("Enter your email")
                    onInput { event ->
                        email = event.value
                    }
                })
            }
        }


        Div {
            Button({
                classes(
                    "w-full",
                    "justify-center",
                    "rounded-md",
                    "bg-zinc-900",
                    "py-3",
                    "text-sm",
                    "font-semibold",
                    "text-white",
                    "shadow-sm",
                    "hover:bg-zinc-800",
                    "focus-visible:outline",
                    "focus-visible:outline-2",
                    "focus-visible:outline-offset-2",
                    "focus-visible:outline-zinc-900",
                    "disabled:hover:bg-zinc-900",
                    "disabled:opacity-75"
                )
                if (uiDisabled) {
                    disabled()
                }
                type(ButtonType.Submit)
            }) {
                Text(if (uiDisabled) "Loading..." else "Submit")
            }
        }
    }
}


@Composable
private fun CreateNewPasskeyForm(
    screenState: TurnkeyInitEmailRecoveryScreenState,
    screenModel: TurnkeyInitEmailRecoveryScreenModel,
    uiDisabled: Boolean
) {
    var recoveryCode by remember { mutableStateOf("") }
    val defaultPasskeyName = screenState.defaultPasskeyName ?: return
    var passkeyName by remember { mutableStateOf(defaultPasskeyName) }
    Form(attrs = {
        classes("space-y-4", "p-4", "max-w-lg", "mx-auto")
        onSubmit {
            it.preventDefault()
            screenModel.createNewAuthenticator(recoveryCode, passkeyName)
        }
    }) {
        if (screenState.recoveryState is RecoveryState.Error) {
            Div({
                classes(
                    "bg-red-100", "border", "border-red-400", "text-red-700", "px-4", "py-3", "rounded", "relative"
                )
                attr("role", "alert")
            }) {
                Div({
                    classes("font-bold")
                }) {
                    Text("Authentication Error:")
                }
                Span({
                    classes("block", "sm:inline")
                }) {
                    Text((screenState.recoveryState as RecoveryState.Error).message)
                }
            }
        }
        Div({
            classes(
                "space-y-4",
                "p-4",
                "max-w-lg",
                "mx-auto",
                "text-center",
                "text-sm",
                "text-green-600",
                "bg-green-100",
                "border-solid",
                "border",
                "border-green-400"
            )
        }) {
            P {
                Text("An email with your recovery code has been sent. ")
                Br()
                B {
                    Text("Please do not close this tab before the end of the recovery process.")
                }
            }
        }
        Div {
            Label(forId = "bundle", attrs = {
                classes("block", "text-sm", "font-medium", "leading-6", "text-zinc-900")
            }) {
                Text("Recovery Code (Check Your Inbox)")
            }
            Div({ classes("mt-2") }) {
                TextArea(recoveryCode, attrs = {
                    classes(
                        "block",
                        "w-full",
                        "rounded-md",
                        "border-0",
                        "py-1.5",
                        "text-zinc-900",
                        "shadow-sm",
                        "ring-1",
                        "ring-inset",
                        "ring-zinc-300",
                        "placeholder:text-zinc-400",
                        "focus:ring-2",
                        "focus:ring-inset",
                        "focus:ring-zinc-900"
                    )
                    if (uiDisabled) {
                        disabled()
                    }
                    id("bundle")
                    name("bundle")
                    inputMode(InputMode.Text)
                    required()
                    placeholder("Paste your recovery code here")
                    onInput { event ->
                        recoveryCode = event.value
                    }
                })
            }
        }

        Div {
            Label(forId = "authenticatorName", attrs = {
                classes("block", "text-sm", "font-medium", "leading-6", "text-zinc-900")
            }) {
                Text("Passkey Name")
            }
            Div({ classes("mt-2") }) {
                TextInput(passkeyName, attrs = {
                    classes(
                        "block",
                        "w-full",
                        "rounded-md",
                        "border-0",
                        "py-1.5",
                        "text-zinc-900",
                        "shadow-sm",
                        "ring-1",
                        "ring-inset",
                        "ring-zinc-300",
                        "placeholder:text-zinc-400",
                        "focus:ring-2",
                        "focus:ring-inset",
                        "focus:ring-zinc-900"
                    )
                    if (uiDisabled) {
                        disabled()
                    }
                    id("authenticatorName")
                    name("authenticatorName")
                    inputMode(InputMode.Text)
                    required()
                    placeholder("Name your new passkey")
                    onInput { event ->
                        passkeyName = event.value
                    }
                })
            }
        }


        Div {
            Button({
                classes(
                    "w-full",
                    "justify-center",
                    "rounded-md",
                    "bg-zinc-900",
                    "py-3",
                    "text-sm",
                    "font-semibold",
                    "text-white",
                    "shadow-sm",
                    "hover:bg-zinc-800",
                    "focus-visible:outline",
                    "focus-visible:outline-2",
                    "focus-visible:outline-offset-2",
                    "focus-visible:outline-zinc-900",
                    "disabled:hover:bg-zinc-900",
                    "disabled:opacity-75"
                )
                if (uiDisabled) {
                    disabled()
                }
                type(ButtonType.Submit)
            }) {
                Text(if (uiDisabled) "Loading..." else "Create New Passkey")
            }
        }
    }
}
